import {MenuCreateOptionInterface} from 'kernel/types/module'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
      ],
    }
  }
}

export default () => (new menu()).get()
